<template>
  <b-card style="min-height: 70vh;">
    <div class="chats">
      <div style="height: inherit">
        <!-- Main Area -->
        <section class="chat-app-window">

          <!-- Chat Content -->
          <div class="active-chat">
            <!-- Chat Navbar -->
            <!-- <div class="chat-navbar">
              <header class="chat-header">

                <!-- Avatar & Name --
                <div class="d-flex align-items-center">

                  <!-- Toggle Icon --
                  <div class="sidebar-toggle d-block d-lg-none mr-1">
                    <feather-icon icon="MenuIcon" class="cursor-pointer" size="21"
                      @click="mqShallShowLeftSidebar = true" />
                  </div>

                  <b-avatar size="36" :src="activeChat.contact.avatar" class="mr-1 cursor-pointer badge-minimal" badge
                    :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                    @click.native="shallShowActiveChatContactSidebar = true" />
                  <h6 class="mb-0">
                    RanAI
                  </h6>
                </div>

              </header>
            </div> -->

            <!-- User Chat Area -->
            <!-- <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"> -->
              <chat-log :chat-data="activeChat" :profile-user-avatar="profileUserDataMinimal.avatar"  @handle-message="handleMessage"/>
            <!-- </vue-perfect-scrollbar> -->

            <!-- Message Input -->
            <b-form class="chat-app-form" @submit.prevent="sendMessage">
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-input v-model="chatInputMessage" placeholder="Enter your message" />
              </b-input-group>
              <b-button v-if="!sent" variant="primary" type="submit">
                Send
              </b-button>
              <b-button v-else variant="primary" disabled>
                loading
              </b-button>
            </b-form>
          </div>
        </section>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText, BAvatar, BButton, BInputGroup, BForm, BFormInput } from 'bootstrap-vue'
import { VuePerfectScrollbar } from 'vue-perfect-scrollbar'
import ChatLog from '@/views/reports/ChatLog.vue'
import Report from '@/api/services/Report.js'


export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BInputGroup,
    BForm,
    BFormInput,
    ChatLog
  },
  data() {
    return {
      sent: false,
      activeChat: {
        "chat": {
          "id": 1,
          "userId": 2,
          "unseenMsgs": 0,
          "messages": [
            {
              "message": "Olá",
              "time": "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
              "senderId": 11,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            },
            {
              "message": "Olá! Estou aqui para te ajudar!",
              "time": "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            },
            {
              "message": "Tente me perguntar sobre:",
              "time": "Mon Dec 11 2018 07:45:16 GMT+0000 (GMT)",
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            },
            {
              "message": '"Quantos candidatos nós temos com departament META, status Hired, start date menor ou igual à hoje?"',
              "time": "Mon Dec 11 2018 07:45:17 GMT+0000 (GMT)",
              "link": true,
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            },
            {
              "message": '"Liste os nome dos candidatos que temos em status In progress e os jobs nos quais estão associados no customer Tiko."',
              "time": "Mon Dec 11 2018 07:45:18 GMT+0000 (GMT)",
              "link": true,
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            },
            {
              "message": '"Quantos candidatos temos em status Hired no cliente Tiko?"',
              "time": "Mon Dec 11 2018 07:45:19 GMT+0000 (GMT)",
              "link": true,
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            },
            {
              "message": '"Qual a soma da revenue cujo a start date é em setembro de 2024, o status é Hired e o department é META?"',
              "time": "Mon Dec 11 2018 07:45:20 GMT+0000 (GMT)",
              "link": true,
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            },
            {
              "message": 'Se eu não tiver uma resposta imediata para sua pergunta, vou guardá-la para estudá-la mais tarde. :)',
              "time": "Mon Dec 11 2018 07:45:21 GMT+0000 (GMT)",
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            }
          ]
        },
        "contact": {
          "id": 2,
          "fullName": "",
          "role": "",
          "about": "",
          "avatar": "/images/ai.png",
          "status": "busy"
        }
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      profileUserDataMinimal: {
        avatar: this.$store.state.portal.profile.employee ? this.$store.state.portal.profile.employee.avatar : ''
      },
      chatInputMessage: ''
    }
  },
  methods: {
    handleMessage (message) {
      this.chatInputMessage = message
      this.sendMessage(message)
    },
    resolveAvatarBadgeVariant (status) {
      if (status === 'online') return 'success'
      if (status === 'busy') return 'danger'
      if (status === 'away') return 'warning'
      return 'secondary'
    },
    sendMessage (event) {
      this.sent = true
      console.log(this.chatInputMessage)
      var now = new Date()
      var message = this.chatInputMessage
      this.chatInputMessage = ''
      this.activeChat.chat.messages.push({
        "message": message,
        "time": now.toString(),
        "senderId": 1,
        "feedback": {
          "isSent": true,
          "isDelivered": true,
          "isSeen": true
        }
      })
      Report.getAIRp({
        'question': message
      }).then(response => {
        this.sent = false
        if (response.data.answer) {
          now = new Date()
          this.activeChat.chat.messages.push({
            "message": response.data.answer.replace(/\n/g, '<br>'),
            "time": now.toString(),
            "senderId": 2,
            "feedback": {
              "isSent": true,
              "isDelivered": true,
              "isSeen": true
            }
          })
        } 
        if (response.data.df) {
          console.log('response.data.df')
          console.log(response.data.df)
          setTimeout(() => {
            var t = new Date()
            this.activeChat.chat.messages.push({
              "message": response.data.df,
              "table": true,
              "time": t.toString(),
              "senderId": 2,
              "feedback": {
                "isSent": true,
                "isDelivered": true,
                "isSeen": true
              }
            })
          }, 1000);
        }
        console.log(response)
      }).catch(() => {
        this.sent = false
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
